import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/components/login.vue'),
  },

  {
    path: '/QianXun',
    name: '千寻登录',
    component: () => import('@/components/Qianxun.vue'),
  },
  {
    path: '/police',
    name: '特警登录',
    component: () => import('@/components/police.vue'),
  },
  {
    path: '/CSX',
    name: '长沙县登录',
    component: () => import('@/components/CSX.vue'),
  },
  {
    path: '/GW',
    name: '国网',
    component: () => import('@/components/GW.vue'),
  },
  {
    path: '/index',
    name: '导航',
    component: () => import('@/components/lay_head.vue'),
    redirect: '/index/project',
    children: [
      {
        path: 'project',
        name: '项目',
        component: () => import('@/components/project/index.vue'),
        redirect: '/index/project/ProjectBar_Level_2',
        children: [
          // {
          //     path: "ProjectBar_Level_1",
          //     name: "一级工程列表详情",
          //     component: () =>
          //         import("@/components/project/ProjectBar_Level_1.vue"),
          // },
          {
            path: 'ProjectBar_Level_2',
            name: '二级工程列表详情',
            component: () => import('@/components/project/ProjectBar_Level_2.vue'),
          },
          // {
          //     path: "ProjectBar_task",
          //     name: "任务列表详情",
          //     component: () => import("@/components/project/ProjectBar_task.vue"),
          // },
          {
            path: 'TaskBar',
            name: '任务',
            component: () => import('@/components/project/TaskBar.vue'),
          },
        ],
      },
      // {
      //     path: "CreateProject",
      //     name: "新建项目,任务",
      //     component: () => import("@/components/project/CreateProject.vue"),
      // },
      {
        path: '/statistics/index',
        name: '飞行统计',
        component: () => import('@/components/statistics/index.vue'),
      },
      {
        path: '/statistics/trajectory-playback',
        name: '轨迹回放',
        component: () => import('@/components/statistics/trajectoryPlayback/index.vue'),
      },
      {
        path: '/statistics/LawPlay',
        name: '执法记录仪回放',
        component: () => import('@/components/statistics/LawPlay/index.vue'),
      },
      {
        path: '/statistics/records',
        name: '飞行记录',
        component: () => import('@/components/statistics/records/index.vue'),
      },
      {
        path: '/live/watch-lists',
        name: '监控列表',
        component: () => import('@/components/live/watch/index.vue'),
      },
      // {
      //     path: "/live/super-watch-lists",
      //     name: "超管监控列表",
      //     component: () => import("@/components/live/watch/super-index.vue"),
      // },
      {
        path: '/live/live-lists',
        name: '实时直播',
        component: () => import('@/components/live/live/index.vue'),
      },
      {
        path: '/rapidBiuldFigure',
        name: '发布快速拼图任务',
        component: () => import('@/components/live/watch/rapidBiuldFigure.vue'),
      },
      {
        path: '/route-specification',
        name: '航线规划',
        component: () => import('@/components/airLine/index.vue'),
      },
      {
        path: '/create/air-line/application',
        name: '创建航线任务',
        component: () => import('@/components/airLine/application.vue'),
      },
      {
        path: 'equipment',
        name: '设备',
        component: () => import('@/components/equipment/index.vue'),
        redirect: '/index/equipment/UavManage',
        children: [
          {
            path: 'UavManage',
            name: '无人机管理',
            component: () => import('@/components/equipment/UavManage.vue'),
          },
          {
            path: 'BatteryManage',
            name: '电池管理',
            component: () => import('@/components/equipment/BatteryManage.vue'),
          },
          {
            path: 'UsageLog',
            name: '使用记录',
            component: () => import('@/components/equipment/UsageLog.vue'),
          },
          {
            path: 'EquipmentMaintenance',
            name: '设备保养管理',
            component: () => import('@/components/equipment/EquipmentMaintenance.vue'),
          },
          {
            path: 'table',
            name: 'table',
            component: () => import('@/components/equipment/table.vue'),
            redirect: '/index/equipment/table/tableOne',
            children: [
              {
                path: 'tableOne',
                name: 'tableOne',
                component: () => import('@/components/equipment/tableOne.vue'),
              },
              {
                path: 'tableTwo',
                name: 'tableTwo',
                component: () => import('@/components/equipment/tableTwo.vue'),
              },
              {
                path: 'tableThree',
                name: 'tableThree',
                component: () => import('@/components/equipment/tableThree.vue'),
              },
              {
                path: 'tableFour',
                name: 'tableFour',
                component: () => import('@/components/equipment/tableFour.vue'),
              },
              {
                path: 'tableFive',
                name: 'tableFive',
                component: () => import('@/components/equipment/tableFive.vue'),
              },
              {
                path: 'tableSix',
                name: 'tableSix',
                component: () => import('@/components/equipment/tableSix.vue'),
              },
            ],
          },
        ],
      },
      // {
      //     path: "SuperAdmin_equipment",
      //     name: "超管设备",
      //     component: () => import("@/components/tempCommon/SuperAdmin.vue"),
      // },
      {
        path: 'team',
        name: '团队',
        component: () => import('@/components/team/index.vue'),
      },
      // {
      //     path: "SuperAdmin_team",
      //     name: "超管团队",
      //     component: () => import("@/components/tempCommon/SuperAdmin.vue"),
      // },
      {
        path: 'resource',
        name: '资源',
        component: () => import('@/components/resource/index.vue'),
      },
      {
        path: 'rapidMap',
        name: '快速拼图',
        component: () => import('@/components/resource/rapidMap.vue'),
      },
      {
        path: 'panorama',
        name: 'vrList',
        component: () => import('@/components/Panorama/index.vue'),
      },
      // {
      //     path: "SuperAdmin_resource",
      //     name: "超管资源",
      //     component: () => import("@/components/tempCommon/SuperAdmin.vue"),
      // },
      {
        path: 'setting',
        name: '设置',
        component: () => import('@/components/setting/index.vue'),
      },
      // {
      //     path: "SuperAdmin_setting",
      //     name: "超管设置",
      //     component: () => import("@/components/tempCommon/SuperAdmin.vue"),
      // },
      {
        // path: "/rapidMap/contrast",
        // name: "快速对比",
        // component: () => import("@/components/resource/contrast.vue"),
        path: '/rapidMap/label',
        name: '快拼标注',
        component: () => import('@/components/resource/label.vue'),
      },
      {
        path: '/rapidMap/screenshot',
        name: '保存截图',
        component: () => import('@/components/resource/screenshot.vue'),
      },
      {
        path: 'media',
        name: '媒体资源',
        component: () => import('@/components/resource/media.vue'),
      },
      {
        path: '/resourceIndex',
        name: '新资源',
        component: () => import('@/components/resource/Terra/index.vue'),
        children: [
          {
            path: 'Terra',
            name: '新资源列表',
            component: () => import('@/components/resource/Terra/resourceList.vue'),
          },
          {
            path: 'establish',
            name: '云计算选择绑定建图任务',
            component: () => import('@/components/resource/Terra/establish.vue'),
          },
          {
            path: 'uploadingPage',
            name: '云计算上传页',
            component: () => import('@/components/resource/Terra/uploadingPage.vue'),
          },
          // {
          //     path: "modelShou",
          //     name: "模型查看",
          //     component: () =>
          //         import("@/components/resource/Terra/modelShou.vue"),
          // },
          // {
          //     path: "orthographicShou",
          //     name: "正射查看",
          //     component: () =>
          //         import("@/components/resource/Terra/orthographicShou.vue"),
          // },
        ],
      },
      {
        path: '/modelShou',
        name: '模型查看',
        component: () => import('@/components/resource/Terra/modelShou.vue'),
      },
      {
        path: '/orthographicShou',
        name: '正射查看',
        component: () => import('@/components/resource/Terra/orthographicShou.vue'),
      },
      // {
      //   path: "/rapidMap/screenshot",
      //   name: "登记",
      //   component: () => import("@/components/resource/screenshot.vue"),

      // }
      {
        path: 'noFlyZone',
        name: '虚拟护栏',
        component: () => import('@/components/NFzone/NFzone.vue'),
      },

      // 任务
      {
        path: '/index/task',
        name: 'task',
        component: () => import('@/components/task/index'),
      },

      // 审批
      {
        path: '/index/examine',
        name: 'examine',
        component: () => import('@/components/task/examine'),
      },
      // 全景图编辑
      {
        path: '/index/edtiPanorama',
        name: 'EdtiPanorama',
        component: () => import('@/components/Panorama/editPanorama.vue'),
      },
      // 全景图详情
      {
        path: '/index/detailPanorama',
        name: 'DetailPanorama',
        component: () => import('@/components/Panorama/detailPanorama.vue'),
      },
      // 全景图-管理
      {
        path: '/index/mgrPanorama',
        name: 'vrMgr',
        component: () => import('@/components/Panorama/mgrPanorma.vue'),
      },
    ],
  },
]

const router = new VueRouter({
    routes,
});

export default router;
