import CryptoJS from 'crypto-js'
var commonFun = {
    //密码加密
    encrypt (str) {
        let key = CryptoJS.enc.Utf8.parse('HUNANDAGOUKEJIGS');
        let strs = CryptoJS.enc.Utf8.parse(str);
        const encryptedStr = CryptoJS.AES.encrypt(strs, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7//Pkcs7 ZeroPadding AnsiX923
        });
        // var result = btoa(encodeURI(encryptedStr));
        return encryptedStr.toString();
    },
}
export default commonFun;
