import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        CreateType: 0,//1->创建一级工程项目,2->创建二级工程
        ProjectListType: 1,//现在展示的数据是: 0->一级工程,1->二级工程,2->任务
        watchData: {},
        unit_id: -1,
        device_id: -1,
        flyer_id: -1,
        task_id: "",
        page: 1,

        uploadType: 5,// 文件类型:1模型,2正射,3快拼
        uploadResidue: 0,// 剩余存储空间
    },
    mutations: {
        // 保存分页器页数
        savePage (state, i) {
            state.page = i;
        },
        // 保存单位id
        saveUnit (state, i) {
            state.unit_id = i;
        },
        // 保存设备分类id
        saveDevice (state, i) {
            state.device_id = i;
        },
        // 保存飞手id
        saveFlyer (state, i) {
            state.flyer_id = i;
        },
        // 保存任务名id
        saveTask (state, i) {
            state.task_id = i;
        },
        ///
        changeFileType (state, i) {
            state.uploadType = i;
        },
        changeResidue (state, i) {
            state.uploadResidue = i;
        },
    },
    actions: {
    },
    modules: {
    }
})
