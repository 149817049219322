import axios from "axios";
import { Notice, LoadingBar } from "view-design";

const instance = axios.create({
  // baseURL: "/Terra",
  baseURL: "",
});

//http 请求拦截器
instance.interceptors.request.use(
  (response) => {
    // LoadingBar.start();
    return response;
  },
  (error) => {
    // LoadingBar.error();
    // Notice.error({
    //     title: '请求错误',
    //     desc: error.response.status
    // });
    return Promise.reject(error);
  }
);
//http 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // LoadingBar.finish();
    return response;
  },
  (error) => {
    // LoadingBar.error();
    // Notice.error({
    //     title: '响应错误',
    //     desc: error.response.status
    // });
    return Promise.reject(error);
  }
);

export function T_post(url, data = {}) {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        // 'Secret': 'ZsjE1NMliheeyaw4kLIYvMjVQegPJu31'//正式服
        Secret: "NmPNayUWjQUPjv9Yqg9TYMuxWSp9FU3s", //测试服
      },
    };
    instance.post(url, data, config).then(
      (response) => {
        if (response.data.code == 2) {
          Notice.error({
            title: "系统提醒",
            desc: response.data.msg,
          });
        } else {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function T_get(url, data = "") {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        // 'Secret': 'ZsjE1NMliheeyaw4kLIYvMjVQegPJu31'//正式服
        Secret: "NmPNayUWjQUPjv9Yqg9TYMuxWSp9FU3s", //测试服
      },
    };
    instance.get(data == "" ? url : `${url}/${data}`, config).then(
      (response) => {
        if (response.data.code == 2) {
          Notice.error({
            title: "系统提醒",
            desc: response.data.msg,
          });
        } else {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function T_Delete(url) {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        // 'Secret': 'ZsjE1NMliheeyaw4kLIYvMjVQegPJu31'//正式服
        Secret: "NmPNayUWjQUPjv9Yqg9TYMuxWSp9FU3s", //测试服
      },
    };
    instance.delete(url, config).then(
      (response) => {
        if (response.data.code == 2) {
          Notice.error({
            title: "系统提醒",
            desc: response.data.msg,
          });
        } else {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}
