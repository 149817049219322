// 定义公共数据和事件
import CryptoJS from 'crypto-js'
import axios from 'axios'
import {
    Notice,
    LoadingBar
} from 'view-design'


let publicData = {
    // 数据
    data: {

    },


    // 方法============================================================
    //密码加密
    encrypt (str) {
        let key = CryptoJS.enc.Utf8.parse('pxbWLL1GGfAwsMuB');
        let iv = CryptoJS.enc.Utf8.parse('');
        const encryptedStr = CryptoJS.AES.encrypt(str, key, {
            iv: iv,
            mode: CryptoJS.mode.CFB,
            padding: CryptoJS.pad.NoPadding
        });
        var result = encryptedStr.toString();
        return result;
    },
    // 验证用户是否登录并返回用户信息
    CheckuserLogin () {
        var user_info = sessionStorage.getItem('user_info') ? sessionStorage.getItem('user_info') : '';
        if (user_info) {
            return JSON.parse(user_info)
        } else {
            Notice.error({
                title: '错误',
                desc: "用户未登录"
            });
            setTimeout(() => {
                window.location.href = '/';
                // router.push("/");
            }, 1000)
        }

    }

};
export default publicData

