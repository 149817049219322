import axios from "axios";
import router from "../router";
import { Notice } from "view-design";

const instance = axios.create({
//   baseURL: "/Java",
  baseURL: "https://www.hnmusk.cn/jflight",
//   baseURL: 'http://192.168.0.162:8097',
})

//http 请求拦截器
instance.interceptors.request.use(
    (config) => {
        config.headers.token = sessionStorage.getItem("token");
        return config;
    },
    (error) => {
        // LoadingBar.error();
        Notice.error({
            title: "请求错误",
            desc: error,
        });
        return Promise.reject(error);
    }
);




//http 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // LoadingBar.finish();
        return response;
    },
    (error) => {
        console.log("error", error.response);
        if (error.response.data.message == "token已失效") {
            Notice.error({
                title: "token已失效",
            });
            router.push("/");
        }
        // LoadingBar.error();
        Notice.error({
            title: "响应错误",
            desc: error,
        });
        return Promise.reject(error);
    }
);

export function Java_post (url, data = {}) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token")
            ? sessionStorage.getItem("token")
            : "";
        instance.post(url, data, {
            headers: {
                "token": token,
            },
        }).then(
            (response) => {
                if (response.data.code == 3) {
                    Notice.error({
                        title: "登录状态过期",
                        desc: response.data.msg_customer,
                    });
                    router.push("/");
                } else if (response.data.code == 1) {
                    resolve(response.data);
                } else {
                    Notice.error({
                        title: "接口连接错误",
                        desc: response.data.msg_customer,
                    });
                }
            },
            (err) => {
                reject(err);
            }
        ).catch((err) => {
            Notice.error({
                title: "登录状态过期",
                desc: err.data,
            });
        }
        );
    });
}

export function Java_fromdata (url, data) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token")
            ? sessionStorage.getItem("token")
            : "";
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": token,
            },
        };
        instance.post(url, data, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}


// axios get请求
export function fetchGet (url, param) {
    return new Promise((resolve, reject) => {
        instance.get(url, { params: param })
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
